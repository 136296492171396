import React, { useContext, useState } from 'react';
import './MyProfile.css';
import { ProfileItem } from './ProfileItem/ProfileItem';
import { Header } from '../Header/Header';
import { ParkingAppContext } from '../../../../../context/parking.context';
import axios from 'axios';
import { parkingService } from '../../../../../modules/parking/parking.service';
import { IInstrumentNumberResponse, IUserData } from '../../../../../modules/parking/parking.types';
import { API_URL } from '../../../../../config';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../components/Toast/Toast';
import { CreditCardOutlined, DeleteOutlined, MailOutlined, PhoneOutlined, RedoOutlined } from '@ant-design/icons';
import { LogoutButton } from '../../../../../components/LogoutButton/LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import cardBrand from '../../../../../utils/CardBrands';
import { Navigate } from 'react-router';

export const MyProfile = () => {
  const { t } = useTranslation();
  const { user, isAuthenticated } = useAuth0();
  const { user_data, card_numbers, token, setParkingAppStateHandler } = useContext(ParkingAppContext);
  const [isPhoneVisible, setPhoneIsVisible] = useState(false);

  const [profileInfo, setProfileInfo] = useState({
    firstName: user_data.firstName || '',
    lastName: user_data.lastName || '',
    email: user_data.email || '',
    phone: user_data.phone || '',
    address: user_data.address || '',
    city: user_data.city || '',
    uuid: user_data.uuid,
  });

  const getUserData = (): void => {
    parkingService
      .getUserData()
      .then((data: IUserData) => {
        setProfileInfo({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
          phone: data.phone || '',
          address: data.address || '',
          city: data.city || '',
          uuid: data.uuid,
        });
        setParkingAppStateHandler({ user_data: data });
      })
      .catch(error => {
        console.error('Error fetching user info:', error);
      });
  };

  const getCardsInfo = (): void => {
    axios
      .get<IInstrumentNumberResponse[]>(`${API_URL}/payspot/payment-instruments?skip=0&limit=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => response.data)
      .then((data: IInstrumentNumberResponse[]) => {
        // Directly using the resolved data
        setParkingAppStateHandler({ card_numbers: data });
      })
      .catch(error => {
        console.error('Error fetching card numbers:', error);
      });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfileInfo({
      ...profileInfo,
      [name]: value,
    });
  };

  const handleSaveChanges = async () => {
    console.log('Saving updated profile:', profileInfo, user_data.uuid);
    try {
      const response = await axios.patch(`${API_URL}/users/${user_data.uuid}`, profileInfo, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      getUserData();
      setProfileInfo({
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        phone: response.data.phone,
        address: response.data.address,
        city: response.data.city,
        uuid: response.data.uuid,
      });
      toast.success(t('system.notifications.success.profileUpdated'));
    } catch (error: any) {
      console.error('Error updating user data:', error);
      toast.error(`${t('system.notifications.error.profileUpdateFailed')} ${JSON.stringify(error?.response?.data)}`);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          getUserData();
          getCardsInfo();
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [token]);

  function handleDeleteCard(e: React.MouseEvent, cardNumber: string): void {
    console.log('Deleting card:', cardNumber);
    const fetchData = async () => {
      try {
        const res = await axios.delete(
          `${API_URL}/payspot/payment-instruments?card_num=${cardNumber}&userId=${user_data.uuid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(res);
        toast.success(t('system.notifications.success.cardDeleted'));
        getCardsInfo();
      } catch (error) {
        toast.error(t('system.notifications.error.cardDeleteFailed'));
        console.error(error);
      }
    };
    fetchData();
  }

  function addNewCard(): void {
    console.log('Clicked add card button!');
    const fetchData = async () => {
      try {
        const res = await axios.get(`${API_URL}/payspot/add-payment-instrument`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(res.data.link);
        window.open(res.data.link, '_blank');
      } catch (error) {
        toast.error(t('system.notifications.error.cardAddFailed'));
        console.error(error);
      }
    };
    fetchData();
  }

  const handleTogglePhone = () => {
    setPhoneIsVisible(prev => !prev); // Toggle visibility
  };

  return (
    <>
      {isAuthenticated && user !== undefined ? (
        <>
          <Header />
          <div className="myProfile">
            <h1>{t('userProfile.title')}</h1>

            <div className="profile-wrapper">
              <div>
                <img src={user.picture} alt="Profile Picture" className="profile-image" />
              </div>
              <div>
                <h3>
                  {profileInfo.firstName} {profileInfo.lastName}
                </h3>
              </div>
              <div>
                <span>{t('userProfile.email')}:</span> <strong>{profileInfo.email}</strong>
              </div>
              <div>
                <span>{t('userProfile.phone')}:</span> <strong>{profileInfo.phone}</strong>
              </div>
              <div>
                <span>{t('userProfile.cityAddress')}:</span> <strong>{profileInfo.address}</strong>
              </div>
              <div>
                <span>{t('userProfile.city')}:</span> <strong>{profileInfo.city}</strong>
              </div>
            </div>

            <ProfileItem
              title={t('userProfile.editProfile')}
              body={
                <div className="profile-edit-form">
                  <label>
                    {t('userProfile.firstName')}:
                    <input type="text" name="firstName" value={profileInfo.firstName} onChange={handleInputChange} />
                  </label>
                  <label>
                    {t('userProfile.lastName')}:
                    <input type="text" name="lastName" value={profileInfo.lastName} onChange={handleInputChange} />
                  </label>
                  <label>
                    {t('userProfile.email')}:
                    <input type="email" name="email" value={profileInfo.email} onChange={handleInputChange} />
                  </label>
                  <label>
                    {t('userProfile.phone')}:
                    <input type="text" name="phone" value={profileInfo.phone} onChange={handleInputChange} />
                  </label>
                  <label>
                    {t('userProfile.cityAddress')}:
                    <input type="text" name="address" value={profileInfo.address} onChange={handleInputChange} />
                  </label>
                  <label>
                    {t('userProfile.city')}:
                    <input type="text" name="city" value={profileInfo.city} onChange={handleInputChange} />
                  </label>
                  <button onClick={handleSaveChanges}>{t('userProfile.saveChanges')}</button>
                </div>
              }
            />
            <ProfileItem
              title={t('userProfile.paymentCards')}
              onclick={getCardsInfo}
              body={
                <div className="cards-wrapper">
                  {card_numbers && card_numbers.length > 0 ? (
                    card_numbers.map(card => (
                      <div key={card.cardNumber} className="card-item">
                        <span>
                          {cardBrand[card.cardType as keyof typeof cardBrand] ? (
                            <img
                              src={cardBrand[card.cardType as keyof typeof cardBrand]}
                              alt={card.cardType}
                              className="card-icon"
                            />
                          ) : (
                            <CreditCardOutlined />
                          )}
                          **** **** **** {card.cardNumber}
                        </span>
                        <button onClick={e => handleDeleteCard(e, card.cardNumber)}>
                          <DeleteOutlined />
                        </button>
                      </div>
                    ))
                  ) : (
                    <>
                      <RedoOutlined onClick={() => getCardsInfo()} />
                      <p>{t('userProfile.noCards')}</p>
                    </>
                  )}
                  <button className="add-card-button" onClick={() => addNewCard()}>
                    {t('userProfile.addNewCard')}
                  </button>
                </div>
              }
            />
            <ProfileItem
              title={t('userProfile.support')}
              body={
                <div className="contact-wrapper">
                  <div>
                    <div>
                      <a href="tel:+381654454445">
                        <PhoneOutlined /> {t('userProfile.helpCenter')}{' '}
                      </a>
                      {t('userProfile.callCenterWorkingHours')}
                      {' - '}
                      <span onClick={handleTogglePhone} style={{ fontStyle: 'italic', cursor: 'pointer' }}>
                        {isPhoneVisible ? ' +381 65 445-4445' : t('userProfile.showNumber')}
                      </span>
                    </div>
                  </div>
                  <div>
                    <a href="mailto:office@parkpilot.ai" target="_blank" rel="noopener noreferrer">
                      <MailOutlined /> {t('userProfile.emailSupport')}
                    </a>
                  </div>
                </div>
              }
            />
            <ProfileItem
              title={t('userProfile.termsOfUsePrivacyPolicy')}
              body={
                <>
                  <p>{t('userProfile.termsText')}</p>
                  <div className="about-us">
                    <div>
                      <a href="https://be.parkpilot.ai/files/Park-Pilot-Uslovi-koristenja.html" target="_blank">
                        {t('userProfile.termsOfUse')}
                      </a>
                    </div>
                    <div>
                      <a href="https://be.parkpilot.ai/files/Park-Pilot-politika-privatnosti.html" target="_blank">
                        {t('userProfile.privacyPolicy')}
                      </a>
                    </div>
                  </div>
                </>
              }
            />
            <LogoutButton />
          </div>
          <Toast />
        </>
      ) : (
        <Navigate to={'/'} replace />
      )}
    </>
  );
};
