import React, { useContext, useEffect } from 'react';
import style from './Header1.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from '../../../../../components/LoginButton/LoginButton';
import { ParkingAppContext } from '../../../../../context/parking.context';
import { parkingService } from '../../../../../modules/parking/parking.service';
// import { parkingService } from '../../../../../modules/parking/parking.service';
// import to from 'await-to-js';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../../components/Button/Button';
import { setHeaders } from '../../../../../utils/axios';
import { useTranslation } from 'react-i18next';
import to from 'await-to-js';
// import { Hamburger } from '../../../../../assets/icons/Hamburger/Hamburger';
// import { toast } from 'react-toastify';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { setParkingAppStateHandler, user_data } = useContext(ParkingAppContext);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setHeaders(token);
        setParkingAppStateHandler({ token: token });
        const [err, res] = await to(parkingService.getUserData());
        if (err || !res) return;
        setParkingAppStateHandler({ user_data: res });
      } catch (error) {
        // console.error('Error getting access token:', error);
      }
    };

    getAccessToken();
  }, [getAccessTokenSilently]);

  // const onClick = async () => {
  //   const [err, res] = await to(parkingService.getListOfReservations());
  //   if (err || !res) return toast.error('Something went wrong');
  //   console.log(res, 'reservation');

  //   setParkingAppStateHandler({ sideBarVisible: true, reservationList: res });
  // };

  return (
    <div className={`${style.header} spaceBetween`}>
      <div className={style.logo}>
        <p>{t('brandHalf')}</p>
        <span>{t('brandHalf2')}</span>
      </div>
      <div className={style.wrapper}>
        <div className={style.center}>
          {isAuthenticated && (
            <div className={style.navLinks}>
              <div className={style.info} onClick={() => navigate('/')}>
                {t('header.map')}
              </div>
              <div className={style.info} onClick={() => navigate('/reservations')}>
                {t('header.reservations')}
              </div>
              <div className={style.info} onClick={() => navigate('/my-profile')}>
                {t('header.profile')}
              </div>
              {user_data.gateManager && (
                <div className={style.info} onClick={() => navigate('/gate-management')}>
                  {t('header.gateManagement')}
                </div>
              )}
            </div>
          )}
        </div>
        <div className={style.right}>
          {!isAuthenticated ? (
            <LoginButton />
          ) : (
            <Button
              name="host"
              onClick={() => {
                setParkingAppStateHandler({ parkingList: [] });
                navigate('/host');
              }}
            >
              {t('header.becomeHost')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
