import { API_URL } from '../../../../../../config';
import { IReservation } from '../../../../../../modules/parking/parking.types';
import QRCode from 'react-qr-code';
import './ReservationItem.css';
import { useTranslation } from 'react-i18next';
import { ModalsContext } from '../../../../../../context/modal.context';
import { useContext, useState } from 'react';
import axios from 'axios';
import { ParkingAppContext } from '../../../../../../context/parking.context';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';

export const ReservationItem: React.FC<IReservation> = props => {
  const { t } = useTranslation();
  const { handleUpdateModals } = useContext(ModalsContext);
  const { setParkingAppStateHandler, reservationList } = useContext(ParkingAppContext);
  const { token } = useContext(ParkingAppContext);
  const [paymentStatus, setPaymentStatus] = useState(props.paymentResponse.paymentStatus);
  const [isPaying, setIsPaying] = useState(false);

  const startTime = props.start * 1000; // unix timestamp in seconds * 1000 = milliseconds
  const startDate = new Date(startTime);

  const PaymentStatus = {
    NOT_STARTED: t('reservation.paymentStatus.unpaid'),
    COMPLETED: t('reservation.paymentStatus.paid'),
  };

  const instance = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });

  const paymentStatusLabel = PaymentStatus[paymentStatus as keyof typeof PaymentStatus];

  const qrCodeUrl = `${API_URL}/reservations/qrcode/details/${props.reservationUuid}`;

  const openQrCodeModal = () => {
    handleUpdateModals({
      modalVisible: 'qr-code-modal',
      modalData: (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
          <QRCode
            value={qrCodeUrl}
            style={{ width: '300px', height: '300px', borderRadius: '10px', padding: '20px' }}
          />
        </div>
      ),
    });
  };

  const onPay = async () => {
    setIsPaying(true);
    try {
      const res = await instance.post(`${API_URL}/reservations/${props.reservationUuid}/payspot/payment`, {
        reservationId: props.reservationUuid,
      });
      if (res.status !== 200) {
        setIsPaying(false);
        toast.error(t('system.notifications.errors.generic'));
      } else {
        setIsPaying(false);
        const newReservationList = reservationList.map(r => {
          if (r.reservationUuid === props.reservationUuid) {
            return { ...r, paymentResponse: res.data.paymentStatus };
          }
          return r;
        });
        setParkingAppStateHandler({ reservationList: newReservationList });
        setPaymentStatus(res.data.paymentStatus);
        res.data.paymentStatus
          ? toast.success(t('system.notifications.success.reservationPaymentSuccess'))
          : toast.error(t('system.notifications.errors.generic'));
      }
    } catch (error) {
      setIsPaying(false);
      console.log(error);
      toast.error(t('system.notifications.errors.generic'));
    }
  };

  return (
    <div className={'box'}>
      <h1>
        {t('reservation.parking')}: {props.parkingResponse.name}
      </h1>
      <h2
        style={{
          color: paymentStatus === 'COMPLETED' ? 'rgba(0, 150, 100, 0.8)' : 'rgba(200, 100, 0, 0.8)',
        }}
      >
        <b>{paymentStatusLabel}</b>
      </h2>
      <p>
        {t('reservation.date')}:{' '}
        {startDate.toLocaleDateString('sr-SR', {
          day: 'numeric',
          month: '2-digit',
          year: 'numeric',
        })}
      </p>
      <p>
        {t('reservation.startTime')}:{' '}
        {startDate.toLocaleTimeString('sr-SR', {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </p>
      <p>
        {t('reservation.duration')}: {props.duration}h
      </p>
      <p>
        {t('reservation.price')}: {props.paymentResponse.amount} {t('util.currency')}
      </p>
      <div className="optionsWrapper">
        {paymentStatus !== 'COMPLETED' && props.parkingResponse.onlinePayment && (
          <button onClick={onPay} disabled={isPaying}>
            {isPaying ? <LoadingOutlined /> : t('reservation.payNow')}
          </button>
        )}
        {!props.parkingResponse.onlinePayment && <p>{t('reservation.notOnlinePaymentInform')}</p>}
        <button onClick={openQrCodeModal}>{t('reservation.qrCode')}</button>
      </div>
    </div>
  );
};
