import React, { useContext } from 'react';
import style from './ParkingInfo.module.scss';
import { ClockCircleOutlined, DollarOutlined, PercentageOutlined } from '@ant-design/icons';

import { ParkingAppContext } from '../../../../../../../../../context/parking.context';
import { WorkingHours } from '../../../../../../../../../components/WorkingHours/WorkingHours';
import LocationIcon from '../../../../../../../../../assets/icons/LocationIcon/LocationIcon';
import { Col, Row } from 'antd';
import { Button } from '../../../../../../../../../components/Button/Button';
import StarRating from '../../../../../../../../../components/StarRating/StarRating';
import { useTranslation } from 'react-i18next';

const ParkingInfo: React.FC = () => {
  const { t } = useTranslation();
  const { parkingPlace } = useContext(ParkingAppContext);
  const redirectToGoogleMaps = () => {
    const mapsURL = `https://www.google.com/maps?q=${parkingPlace.latitude},${parkingPlace.longitude}`;
    window.open(mapsURL, '_blank');
  };
  console.log(parkingPlace, 'parkingPlace');

  return (
    <div className={style.mainInfo}>
      <Row justify="space-between" className={style.rating}>
        <div className={style.name}>{parkingPlace.name}</div>
        {parkingPlace.parkingPriceMap && Object.keys(parkingPlace.parkingPriceMap).length > 0 && (
          <div style={{ display: 'flex', alignItems: 'start', fontSize: '1.1rem' }}>
            <PercentageOutlined />
          </div>
        )}
      </Row>
      <Col className={style.col}>
        <div className={style.icons}>
          <LocationIcon />
        </div>
        <div>{parkingPlace.address}</div>
      </Col>

      <Col className={style.col}>
        <div className={style.icons}>
          <DollarOutlined />
        </div>
        <div>
          {parkingPlace.price} {t('util.rsPerHour')}
        </div>
      </Col>

      <Col className={style.col}>
        <div className={style.icons}>
          <ClockCircleOutlined />
        </div>
        <WorkingHours />
      </Col>

      <StarRating averageStar={parkingPlace.averageStar} />
      <div className={style.button}>
        <Button name="direction" onClick={redirectToGoogleMaps}>
          {t('parking.directions')} {'>'}
          {'>'}
          {'>'}
        </Button>
      </div>
    </div>
  );
};

export default ParkingInfo;
