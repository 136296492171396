import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect } from 'react';
import { Header } from '../Header/Header';
import styles from './GateManagement.module.scss';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router';
import { parkingService } from '../../../../../modules/parking/parking.service';
import { ParkingAppContext } from '../../../../../context/parking.context';
import to from 'await-to-js';
import { hostService } from '../../../../../modules/host/host.service';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../components/Toast/Toast';
import { CheckCircleOutlined, DownCircleOutlined, MonitorOutlined, PoweroffOutlined } from '@ant-design/icons';
export const GateManagement: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const { user_data, gateAccessParkingList, setParkingAppStateHandler } = useContext(ParkingAppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [parkingDeviceStatus, setParkingDeviceStatus] = React.useState<any>({});

  useEffect(() => {
    if (!user_data.gateManager) {
      navigate('/');
    }
  }, [user_data, navigate]);

  useEffect(() => {
    if (user_data.uuid !== '') {
      const getParkingsWithGateAccess = async () => {
        console.log(user_data.uuid);
        const [err, res] = await to(parkingService.getUserGateManagerParkings(user_data.uuid));
        if (err || !res) return;
        setParkingAppStateHandler({ gateAccessParkingList: res });
      };
      getParkingsWithGateAccess();
    }
  }, [user_data]);

  const checkParkingStatus = async (parking: any) => {
    try {
      const res = await hostService.getDeviceStatus(parking.uuid);
      console.log(res);
      toast.info(
        `${t('gateManagement.gateStatusSuccess')}: ${
          res.status === 'open' ? t('gateManagement.gateStatus.available') : t('gateManagement.gateStatus.unavailable')
        }`
      );
      setParkingDeviceStatus({ ...parkingDeviceStatus, [parking.uuid]: res.status });
    } catch (error) {
      console.error(error);
      toast.error(t('gateManagement.gateStatusError'));
      setParkingDeviceStatus({ ...parkingDeviceStatus, [parking.uuid]: 'ERROR' });
    }
  };

  const toggleDevice = (parking: any) => {
    const payloadDeviceCommand = async (command: string) => {
      try {
        const res = await hostService.postDeviceCommander({
          command: command,
          parkingId: parking.uuid,
        });
        console.log(res);
        toast.success(t('gateManagement.gateToggleSuccess'));
      } catch (error) {
        console.error(error);
        toast.error(t('gateManagement.gateToggleError'));
      }
      checkParkingStatus(parking);
    };
    payloadDeviceCommand('TRIGGER');
  };

  return (
    <>
      {isAuthenticated && user_data !== undefined ? (
        <>
          <Header />
          <div className={styles.container}>
            <h1 className={styles.title}>{t('gateManagement.title')}</h1>
            {gateAccessParkingList.length > 0 ? (
              <div className={styles.parkingList}>
                {gateAccessParkingList.map((parking: any, index: number) => (
                  <div key={index} className={styles.parkingItem}>
                    <img src={parking.imageUrl} alt={parking.name} />
                    <h2>
                      {t('gateManagement.parkingName')}: {parking.name}
                    </h2>
                    <p>
                      {t('gateManagement.parkingAddress')}: {parking.address}
                    </p>
                    {parking.devicePresent ? (
                      <div className={styles.controlsContainer}>
                        <button className={styles.statusButton} onClick={() => checkParkingStatus(parking)}>
                          <MonitorOutlined />
                          <span>{t('gateManagement.checkStatus')}</span>
                        </button>
                        {parkingDeviceStatus[parking.uuid] && parkingDeviceStatus[parking.uuid] !== 'ERROR' && (
                          <div className={styles.signalContainer}>
                            <p>
                              {t('gateManagement.gateStatusSuccess')}:{' '}
                              {parkingDeviceStatus[parking.uuid] === 'open' ? (
                                <>
                                  <CheckCircleOutlined style={{ color: 'green' }} />{' '}
                                  {t('gateManagement.gateStatus.available')}{' '}
                                </>
                              ) : (
                                <>
                                  <DownCircleOutlined style={{ color: 'red' }} />{' '}
                                  {t('gateManagement.gateStatus.unavailable')}{' '}
                                </>
                              )}
                            </p>
                            <button className={styles.signalButton} onClick={() => toggleDevice(parking)}>
                              <PoweroffOutlined />
                              <span>{t('gateManagement.sendSignal')}</span>
                            </button>
                          </div>
                        )}
                        {parkingDeviceStatus[parking.uuid] === 'ERROR' && <p>{t('gateManagement.gateStatusError')}</p>}
                      </div>
                    ) : (
                      <p>{t('gateManagement.noDevice')}</p>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p>{t('gateManagement.noParking')}</p>
            )}
          </div>
        </>
      ) : (
        <Navigate to={'/'} replace />
      )}
      <Toast />
    </>
  );
};

export default GateManagement;
