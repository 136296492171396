import { useContext } from 'react';
import { ModalsContext } from '../../../../../../../../../context/modal.context';
import { ParkingAppContext } from '../../../../../../../../../context/parking.context';
import { IParkingPlaces } from '../../../../../../../../../modules/parking/parking.types';
import style from './ListItem.module.scss';
import ImageComponent from '../../../../../../../../../components/ImageComponent/ImageComponent';
import { useTranslation } from 'react-i18next';
// import { CarIcon } from '../../../../../../../../../assets/icons/CarIcon/CarIcon';

interface ItemProps {
  place: IParkingPlaces;
  index: number;
}

export const ListItem = (props: ItemProps) => {
  const { t } = useTranslation();
  const { handleUpdateModals } = useContext(ModalsContext);
  const { setParkingAppStateHandler } = useContext(ParkingAppContext);

  const setInfoParkingModal = async () => {
    handleUpdateModals({ modalVisible: 'InfoParkingModal' });
    setParkingAppStateHandler({ parkingPlace: props.place, isInfoParkingVisible: true });
  };

  return (
    <div className={style.wrapper}>
      <div className={`${style.item} `} key={props.index} id={props.index.toString()} onClick={setInfoParkingModal}>
        <div className={style.img}>
          <ImageComponent imageUrl={props.place.imageUrl} />
        </div>
        <div className={style.right}>
          <div className={style.name}>{props.place.name}</div>
          <div className={style.address}>{props.place.address}</div>
          <div className={style.price}>
            {props.place.price} {t('util.rsPerHour')}
          </div>
        </div>
      </div>
    </div>
  );
};
