import React, { useContext, useEffect, useState } from 'react';
import { Modal } from '../../Modal/Modal';
import { ModalsContext } from '../../../context/modal.context';
import style from './ReservationModal.module.scss';
import { DateTimeDurationForm } from '../../DateTimeDurationForm/DateTimeDurationForm';
import { ParkingAppContext } from '../../../context/parking.context';
import { Button, Col, Form, Row, Select } from 'antd';
import ParkingInfo from '../../../pages/Customer/HomePage/components/Main/components/InfoParkingModal/components/ParkingInfo/ParkingInfo';
import { ReservationDeleteChangeBtn } from './components/ReservationDeleteChangeBtn/ReservationDeleteChangeBtn';
import { IReservationRequest } from '../../../modules/parking/parking.types';
// import { useNavigate } from 'react-router';
import { CloseButton } from '../../CloseButton/CloseButton';
import ImageComponent from '../../ImageComponent/ImageComponent';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { API_URL } from '../../../config';
import { useTranslation } from 'react-i18next';
import { CreditCardOutlined } from '@ant-design/icons';
import { parkingService } from '../../../modules/parking/parking.service';
import cardBrand from '../../../utils/CardBrands';
import to from 'await-to-js';
//import { isEmpty } from 'lodash';

interface IReservationProps {
  onOk?: () => any;
  onCancel?: () => any;
}

export const ReservationModal: React.FC<IReservationProps> = () => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const [discountedPrice, setDiscountedPrice] = useState(null);

  const { isModalVisible, clearModals, modals } = useContext(ModalsContext);

  const { parkingPlace, timeDateDuration, card_numbers, token } = useContext(ParkingAppContext);

  console.log('price from parkingPlace: ', parkingPlace.price);

  //const [cardNumbers, setCardNumbers] = useState(card_number);

  console.log(user);
  console.log(token);

  const [reservation, setReservation] = useState<IReservationRequest>({
    parkingId: parkingPlace.uuid,
    uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    guestId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
    duration: 1,
    start: null,
    cardNumber: '',
    //price: 0,
    price: parkingPlace.price,
    onlinePayment: parkingPlace.onlinePayment,
  });
  // const navigate = useNavigate();
  //const ADDCARD = 'payspot/add-payment-instrument';

  const instance = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });

  /*
  instance.get(`${API_URL}/payspot/add-payment-instrument`).then(response => {
    return response.data;
  });
*/

  const addCard = () => {
    console.log('Clicked add card button!');

    const fetchData = async () => {
      try {
        //const res = await axios.get(`${API_URL}/payspot/add-payment-instrument`);

        const res = await instance.get(`/payspot/add-payment-instrument`);
        //console.log(res);
        // moj dodatak
        //clearModals();
        //navigate(res.data.link);
        console.log(res.data.link);
        //window.location.href = res.data.link;
        window.open(res.data.link, '_blank');
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };
    fetchData();
    /*
   

    navigate(addcard);
    */
  };

  useEffect(() => {
    if (parkingPlace.uuid) {
      setDiscountedPrice(null);
      setReservation({ ...reservation, parkingId: parkingPlace.uuid, price: parkingPlace.price });
    }
  }, [parkingPlace.uuid]);
  useEffect(() => {
    const fetchDiscountedPrice = async () => {
      const [err, res] = await to<any>(
        parkingService.getDiscountedPrice({ parkingId: parkingPlace.uuid, duration: timeDateDuration.duration })
      );
      if (err) {
        console.error(err);
        return;
      }
      if (!res || !res.totalPrice) return;
      console.log(res.totalPrice);
      setDiscountedPrice(res.totalPrice);
    };
    if (parkingPlace.uuid) fetchDiscountedPrice();
    setReservation({
      ...reservation,
      duration: timeDateDuration.duration,
      start: timeDateDuration.start,
    });
  }, [timeDateDuration]);

  /*
  useEffect(() => {
    setReservation({
      ...reservation,
      cardNumber: card_number[length - 1].cardNumber,
    });
  }, [card_number]);
  */
  /*
  useEffect(() => {
    const fetchCard = async () => {
      try {
        //const response = await axios.get<IResponseCard>(`https://parkpilot.ai/api/payment/user?token=${token}`);
        const response = await instance.get(`/payspot/payment-instruments?skip=0&limit=10`);
        if (!isEmpty(response.data)) {
          return setParkingAppStateHandler({ card_number: response.data }); // data.user_cards
        }
        //navigate('/payment');
        //navigate(response.data.link);
        console.log(response.data[0]);
        console.log(card_number);
      } catch (err) {
        throw new Error(`Error fetching data`);
      }
    };
    fetchCard();
  }, []);
*/
  return (
    <Modal
      name="reservation-parking-modal"
      closable={false}
      open={isModalVisible('reservation-parking-modal')}
      className={`.and-modal-wrap .ant-modal`}
      style={{ fontWeight: 'bold' }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={'600px'}
    >
      <Form layout="vertical" name="reservation-form">
        <div>
          <div
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
            }}
          >
            <CloseButton onClick={() => clearModals()} />
          </div>

          <div>
            <div className={style.img}>
              <ImageComponent imageUrl={parkingPlace.imageUrl} />
            </div>
            <div
              style={{
                position: 'absolute',
                left: '0px',
                top: '0px',
                display: 'flex',
              }}
            >
              <ParkingInfo />
            </div>
          </div>
          <div className={style.main}>
            <div className={style.right}>
              {modals.modalType === 'reservation' && parkingPlace.onlinePayment ? (
                <Row justify={'space-between'}>
                  <Col>{t('payment.selectCard')}</Col>
                  <Col span={12}>
                    <Select
                      placeholder={t('payment.selectCardPlaceholder')}
                      onChange={(_, option: any) => {
                        const cardNumber = option;
                        setReservation(reservation => ({ ...reservation, cardNumber: cardNumber.value }));
                        console.log(reservation);
                      }}
                    >
                      {card_numbers.map(card => {
                        return (
                          <Select.Option key={card.cardNumber} value={card.cardNumber}>
                            <>
                              {cardBrand[card.cardType as keyof typeof cardBrand] ? (
                                <img
                                  src={cardBrand[card.cardType as keyof typeof cardBrand]}
                                  alt={card.cardType}
                                  className={style.cardIcon}
                                />
                              ) : (
                                <CreditCardOutlined />
                              )}
                              **** **** **** {card.cardNumber}
                            </>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>

                  <Col>
                    <Button name="add-card" onClick={addCard}>
                      {t('payment.addCard')}
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row justify={'center'}>
                  <Col>{t('reservation.postponedReservation')}</Col>
                </Row>
              )}
              <DateTimeDurationForm state={reservation} setState={setReservation} />
              {parkingPlace.parkingPriceMap && Object.keys(parkingPlace.parkingPriceMap).length > 0 && (
                <Row justify={'space-between'}>
                  <Col>
                    {Object.entries(parkingPlace.parkingPriceMap).map(([key, value]) => (
                      <Row key={key}>
                        {t(`reservation.${key}`)}: {value} {t('util.currency')}
                      </Row>
                    ))}
                  </Col>
                  <Col>
                    {t('reservation.price')}: {discountedPrice === null ? reservation.price : discountedPrice}{' '}
                    {t('util.currency')}
                  </Col>
                </Row>
              )}
              <ReservationDeleteChangeBtn payload={reservation} />
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
