import React, { Dispatch, SetStateAction, useContext } from 'react';
import { DatePicker, InputNumber } from 'antd';
import style from './DateTimeDurationForm.module.scss';
import { IFormAddressToGo, IReservationRequest } from '../../modules/parking/parking.types';
import { ParkingAppContext } from '../../context/parking.context';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface IDatePickerProps {
  setState: Dispatch<SetStateAction<any>>;
  state: IFormAddressToGo | IReservationRequest;
}

export const DateTimeDurationForm: React.FC<IDatePickerProps> = props => {
  console.log('Date Time Picker (props):', props.state.start?.valueOf());
  console.log(props.state);
  const { t } = useTranslation();
  const { timeDateDuration, setParkingAppStateHandler } = useContext(ParkingAppContext);

  const changeState = (e: any, type: string) => {
    setParkingAppStateHandler({ timeDateDuration: { ...timeDateDuration, [type]: e } });
    props.setState({ ...props.state, [type]: e });
  };

  const disableDatesBefore = (): any['disabledDate'] => {
    const day = new Date() as dayjs.ConfigType;
    return (current: any) => {
      const specified = dayjs(day);
      return current && current < specified.startOf('day');
    };
  };

  return (
    <div className={style.wrapperDateTimeDuration}>
      <div className={style.dateAndTime}>
        <label>{t('search.date')}:</label>
        <DatePicker
          placeholder={t('search.datePlaceholder')}
          aria-required={true}
          showTime={true}
          format="DD.MM.YYYY HH:mm"
          onChange={e => changeState(e, 'start')}
          minuteStep={15 as const}
          hourStep={1 as const}
          value={props.state.start as any}
          showToday={true}
          disabledDate={disableDatesBefore()}
        />
      </div>
      <div className={style.duration}>
        <label>{t('search.duration')}:</label>
        <div>
          <InputNumber
            required={true}
            value={props.state.duration as any}
            name="duration"
            onChange={e => changeState(e, 'duration')}
            step={1}
            min={1}
          />
          <span>/h</span>
        </div>
      </div>
    </div>
  );
};
