import { ApiResponse, axios } from '../../utils/axios';
import {
  IAddDevice,
  IDeviceCommander,
  IParkingPlaces,
  IHostData,
  IDeviceCommanderResponse,
  IDeviceState,
} from '../parking/parking.types';
import queryString from 'query-string';

const ROUTES = {
  PARKING: 'parkings/',
  CREATE: 'create',
  USERS: 'users/',
  DEVICES: 'devices',
  COMMANDER: '/commander',
  STATE: '/state',
};

const postNewParking = (payload: any): ApiResponse<IParkingPlaces> => {
  const query = queryString.stringify(payload.data);
  return axios.post(`parkings?${query}`, payload.profileImage, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getHostParkings = (): ApiResponse<IParkingPlaces[]> => {
  return axios.get(`users/parkings`);
};

const deleteParking = (id: string) => {
  return axios.delete(`${ROUTES.PARKING}${id}`);
};

const patchParking = (payload: any) => {
  const query = queryString.stringify(payload);
  return axios.patch(`parkings?${query}`);
};

// User Data
const postHostData = (payload: any): ApiResponse<IHostData> => {
  //const query = queryString.stringify(payload);
  return axios.post(`/users`, payload, {
    //return axios.post(`${ROUTES.USERS}`, payload, {
    //return axios.post(`users`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getHostData = (id: string): ApiResponse<IHostData> => {
  //return axios.get(`${ROUTES.USERS}${id}`);
  return axios.get(`users/${id}`);
};

const postDevice = (payload: any): ApiResponse<IAddDevice> => {
  return axios.post(`${ROUTES.DEVICES}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const postDeviceCommander = (payload: IDeviceCommander): ApiResponse<IDeviceCommanderResponse> => {
  return axios.post(`${ROUTES.DEVICES}${ROUTES.COMMANDER}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getDeviceStatus = (parkingId: string): ApiResponse<IDeviceState> => {
  return axios.get(`${ROUTES.DEVICES}${ROUTES.STATE}`, { params: { parkingId } });
};

export const hostRepo = {
  postNewParking,
  getHostParkings,
  deleteParking,
  patchParking,
  postHostData,
  getHostData,
  postDevice,
  postDeviceCommander,
  getDeviceStatus,
};
