import { useContext } from 'react';
import { ParkingAppContext } from '../../../../../../context/parking.context';
import style from './HostParkingList.module.scss';
import { HostListItem } from './components/HostListItem/HostListItem';
export const HostParkingList: React.FC = () => {
  const { hostParkingList } = useContext(ParkingAppContext);
  return (
    <div className={style.horizontal}>
      <div>
        {hostParkingList &&
          hostParkingList.map((place: any, index: number) => <HostListItem place={place} key={index} index={index} />)}
      </div>
    </div>
  );
};
