import React from 'react';
import styles from './HostHomePage.module.scss';
import { HostParking } from './components/HostParking/HostParking';
import { Header } from '../Customer/HomePage/components/Header/Header';
import { Toast } from '../../components/Toast/Toast';
export const HostHomePage: React.FC = () => {
  return (
    <>
      <Header />
      <div className={styles.wrapper}>
        <HostParking />
      </div>
      <Toast />
    </>
  );
};
