import { createContext, useState } from 'react';
import {
  IAddDevice,
  IComment,
  IInstrumentNumberResponse,
  IParkingPlaces,
  IReservation,
  ITimeDateDuration,
  IHostData,
  IUserData,
  IWorkingHours,
} from '../modules/parking/parking.types';
import dayjs from 'dayjs';

interface IParkingAppContext extends IParkingAppState {
  parkingAppState: IParkingAppState;
  setParkingAppStateHandler: (params: Partial<IParkingAppState>) => void;
}

const INITIAL_WORKING_HOURS = [
  {
    dayInAWeek: 0,
    openTime: 0,
    closeTime: 0,
  },
];
export const INITIAL_PARKING_PLACE = {
  uuid: '',
  name: '',
  address: '',
  averageStar: 0,
  price: 0,
  basePrice: 0,
  parkingPriceMap: {
    priceHour: 0,
    priceWorkday: 0,
    priceFullDay: 0,
  },
  baseParkingPriceMap: {
    priceHour: 0,
    priceWorkday: 0,
    priceFullDay: 0,
  },
  imageUrl: '',
  latitude: null,
  longitude: null,
  userId: '',
  workingHours: INITIAL_WORKING_HOURS,
  devicePresent: false,
  fee: 0,
  onlinePayment: false,
};

const INITIAL_COMMENT = {
  uuid: '',
  userName: '',
  parkingUuid: '',
  body: '',
};

const INITIAL_RESERVATION = {
  reservationUuid: '',
  start: 0,
  duration: 1,
  status: '',
  parkingResponse: {
    price: 0,
    latitude: 0,
    longitude: 0,
    imageUrl: '',
    image: '',
    uuid: '',
    userId: '',
    name: '',
    address: '',
    averageStar: 0,
    workingHours: INITIAL_WORKING_HOURS,
    devicePresent: false,
    onlinePayment: false,
    fee: 0,
  },
  customerResponse: {
    firstName: '',
    lastName: '',
    email: '',
    uuid: '',
  },
  paymentResponse: {
    paymentStatus: '',
    amount: 0.0,
  },
};
// Test
const INITIAL_TIME_DATE_DURATION = {
  start: dayjs(),
  duration: 1,
};

export const INITIAL_PLACE_TO_GO = {
  address: '',
  ...INITIAL_TIME_DATE_DURATION,
};

// Host data initial const
const INITIAL_USER_DATA = {
  pictureUrl: '',
  uuid: '',
  city: '',
  address: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  admin: false,
  country: '',
  emailVerified: false,
  postalCode: '',
  gateManager: false,
};

const INITIAL_HOST_DATA = {
  pictureUrl: '',
  uuid: '',
  city: '',
  address: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  admin: false,
  country: '',
  emailVerified: false,
  postalCode: '',
  gateManager: false,
  bankAccount: '',
};

const INITIAL_DEVICE_DATA = {
  parkingId: '',
  securityKey: '',
  authKey: '',
  ip: '',
};

export interface IParkingAppState {
  parkingList: IParkingPlaces[] | any;
  hostParkingList: IParkingPlaces[] | any;
  gateAccessParkingList: IParkingPlaces[] | any;
  parkingPlace: IParkingPlaces;
  workingHours: IWorkingHours[];
  isInfoParkingVisible: boolean;
  sideBarVisible: boolean;
  commentList: IComment[];
  comment: IComment;
  reservationList: IReservation[];
  reservation: IReservation;
  timeDateDuration: ITimeDateDuration;
  token: string;
  card_numbers: IInstrumentNumberResponse[];
  host_data: IHostData;
  user_data: IUserData;
  device_data: IAddDevice;
  currentParkingId: string;
}

const initialParkingAppState = {
  parkingList: [],
  hostParkingList: [],
  gateAccessParkingList: [],
  parkingPlace: INITIAL_PARKING_PLACE,
  workingHours: INITIAL_WORKING_HOURS,
  isInfoParkingVisible: false,
  sideBarVisible: false,
  commentList: [],
  comment: INITIAL_COMMENT,
  reservationList: [],
  reservation: INITIAL_RESERVATION,
  timeDateDuration: INITIAL_TIME_DATE_DURATION,
  token: '',
  card_numbers: [],
  host_data: INITIAL_HOST_DATA,
  user_data: INITIAL_USER_DATA,
  device_data: INITIAL_DEVICE_DATA,
  currentParkingId: '',
};

export const ParkingAppContext = createContext<IParkingAppContext>(null as any);

export const ParkingAppProvider = ({ children }: { children: any }) => {
  const [parkingAppState, setParkingAppState] = useState<IParkingAppState>(initialParkingAppState);

  const setParkingAppStateHandler = (valueToUpdate: Partial<IParkingAppState>) => {
    setParkingAppState(prev => ({ ...prev, ...valueToUpdate }));
  };

  return (
    <ParkingAppContext.Provider
      value={{
        parkingAppState: parkingAppState,
        parkingList: parkingAppState.parkingList,
        hostParkingList: parkingAppState.hostParkingList,
        gateAccessParkingList: parkingAppState.gateAccessParkingList,
        parkingPlace: parkingAppState.parkingPlace,
        workingHours: parkingAppState.workingHours,
        isInfoParkingVisible: parkingAppState.isInfoParkingVisible,
        sideBarVisible: parkingAppState.sideBarVisible,
        commentList: parkingAppState.commentList,
        comment: parkingAppState.comment,
        reservationList: parkingAppState.reservationList,
        reservation: parkingAppState.reservation,
        timeDateDuration: parkingAppState.timeDateDuration,
        token: parkingAppState.token,
        card_numbers: parkingAppState.card_numbers,
        host_data: parkingAppState.host_data,
        user_data: parkingAppState.user_data,
        device_data: parkingAppState.device_data,
        currentParkingId: parkingAppState.currentParkingId,
        setParkingAppStateHandler,
      }}
    >
      {children}
    </ParkingAppContext.Provider>
  );
};
