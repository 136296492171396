import defaultImage from '../../assets/img/parking.jpeg';
import styles from './ImageComponent.module.scss';
interface ImageProps {
  imageUrl: string;
}

const ImageComponent: React.FC<ImageProps> = props => {
  console.log(props.imageUrl);
  return (
    <div className={styles.imageWrapper}>
      <img src={props.imageUrl} alt="Image" onError={e => (e.currentTarget.src = defaultImage)} />
    </div>
  );
};

export default ImageComponent;
