import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { ModalsContext } from './context/modal.context';
import './styles/ant-overrides.scss';
import './styles/globals.scss';
import style from './App.module.scss';
import { HostHomePage } from './pages/Host/HostHomePage';
import { HostParking } from './pages/Host/components/HostParking/HostParking';
import { Reports } from './pages/Host/components/Reports/Reports';

import { HomePage } from './pages/Customer/HomePage/HomePage';
import { InfoModal } from './components/Modals/InfoModal/InfoModal';
import Payment from './pages/Payment/Payment';
//import { Reservations } from './pages/Customer/HomePage/components/Reservations/Reservations';
import { Reservations } from './pages/Customer/HomePage/components/Reservations/Reservations';
import { MyProfile } from './pages/Customer/HomePage/components/MyProfile/MyProfile';
import { GateManagement } from './pages/Customer/HomePage/components/GateManagement/GateManagement';
function App() {
  const { loaderRef, modals } = useContext(ModalsContext);

  return (
    <div className={style.app}>
      <LoadingBar color="#0364ca" ref={loaderRef} />
      <InfoModal message={modals.infoMessage} title={modals.infoTitle} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/*<Route path="/reservations" element={<Reservations />} />*/}
        <Route path="/reservations" element={<Reservations />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/gate-management" element={<GateManagement />} />
        <Route path="host" element={<HostHomePage />}>
          <Route index element={<HostParking />} />
          <Route path="info" element={<HostParking />} />
          <Route path="reports" element={<Reports />} />
        </Route>
        <Route path="payment" element={<Payment />} />
        <Route path="*" element={<Navigate to={'/'} replace />} />
      </Routes>
    </div>
  );
}

export default App;
