import { ApiResponse, axios } from '../../utils/axios';

import {
  IParkingPlaces,
  IFormAddressToGo,
  IRequestParkingPlace,
  IComment,
  ICommentRequest,
  //IReservationRequest,
  IReservation,
  IReservationOuterRequest,
  IUserData,
} from './parking.types';

const ROUTES = {
  PARKING: 'parkings',
  ADDRESS: 'address',
  COMMENTS: 'comments',
  RESERVATION: 'reservations',
  USERS: 'users',
};
const getParkingPlacesByLocation = (location: {
  latitude: number;
  longitude: number;
}): ApiResponse<IParkingPlaces[]> => {
  return axios.get(`${ROUTES.PARKING}`, { params: { lat: location.latitude, longit: location.longitude, radius: 5 } });
};
const getUserData = (): ApiResponse<IUserData> => {
  return axios.get(`${ROUTES.USERS}/me`);
};

const getUserGateManagerParkings = (userId: string): ApiResponse<IParkingPlaces[]> => {
  return axios.get(`${ROUTES.USERS}/${userId}/gate-manager/parkings`);
};

const getDiscountedPrice = (payload: { parkingId: string; duration: number }): ApiResponse<number> => {
  return axios.post(`${ROUTES.PARKING}/calculate`, null, { params: payload });
};

const getParkingPlaces = (): ApiResponse<IParkingPlaces[]> => {
  return axios.get(`${ROUTES.PARKING}`);
};

const getParking = (id: string): ApiResponse<IParkingPlaces> => {
  return axios.get(`${ROUTES.PARKING}/${id}`);
};

const getParkingWithAddress = (values: IFormAddressToGo): ApiResponse<IParkingPlaces[]> => {
  return axios.get(`${ROUTES.PARKING}/${ROUTES.ADDRESS}`, { params: values });
};

const getComments = (payload: IRequestParkingPlace): ApiResponse<IComment[]> => {
  return axios.get(`${ROUTES.PARKING}/${payload.id}/${ROUTES.COMMENTS}`);
};

const getListOfReservations = (): ApiResponse<IReservation[]> => {
  return axios.get(`${ROUTES.USERS}/${ROUTES.RESERVATION}`);
};

const postComment = (payload: ICommentRequest): ApiResponse<IComment> => {
  return axios.post(`${ROUTES.COMMENTS}`, payload);
};

const postReservation = (payload: IReservationOuterRequest): ApiResponse<IReservation> => {
  return axios.post(`${ROUTES.RESERVATION}`, payload);
};

const payReservation = (reservationUuid: string) => {
  return axios.post(`${ROUTES.RESERVATION}/${reservationUuid}/payspot/payment`, { reservationId: reservationUuid });
};

const deleteReservation = (id: string) => {
  return axios.delete(`${ROUTES.RESERVATION}/${id}`);
};

const changeReservation = (payload: IReservationOuterRequest): ApiResponse<IReservation> => {
  return axios.patch(`${ROUTES.RESERVATION}`, payload);
};

export const parkingRepo = {
  getParkingPlacesByLocation,
  getParkingPlaces,
  getParkingWithAddress,
  getComments,
  postComment,
  postReservation,
  payReservation,
  getListOfReservations,
  getParking,
  deleteReservation,
  changeReservation,
  getDiscountedPrice,
  getUserData,
  getUserGateManagerParkings,
};
