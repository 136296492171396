import React, { useEffect } from 'react';
import style from './DayPickerNewParking.module.scss';
import { TimePicker, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const { RangePicker } = TimePicker;

interface Props {
  parentForm: any; // Accepts the parent form instance
}

export const DayPickerNewParking: React.FC<Props> = ({ parentForm }) => {
  const { t } = useTranslation();

  // List of weekdays
  const weekdays = [
    { key: 'monday', name: 'start1' },
    { key: 'tuesday', name: 'start2' },
    { key: 'wednesday', name: 'start3' },
    { key: 'thursday', name: 'start4' },
    { key: 'friday', name: 'start5' },
    { key: 'saturday', name: 'start6' },
    { key: 'sunday', name: 'start7' },
  ];

  // Default time values (00:00 - 24:00)
  const defaultTime = [dayjs('00:00', 'HH:mm'), dayjs('24:00', 'HH:mm')];

  // Set initial values in the parent form when the component mounts
  useEffect(() => {
    const defaultValues = weekdays.reduce((acc, { name }) => {
      acc[name] = defaultTime;
      return acc;
    }, {} as Record<string, dayjs.Dayjs[]>);

    parentForm.setFieldsValue(defaultValues);
  }, [parentForm]);

  // Handle mobile focus issue
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (window.innerWidth < 768) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className={style.dayPickerWrapper} id="time-per-day">
      {weekdays.map(({ key, name }) => (
        <Form.Item
          key={key}
          label={t(`util.weekdays.${key}`)}
          name={name}
          className={style.day}
          rules={[{ required: true, message: t('util.selectTime') }]}
        >
          <RangePicker
            format="HH a"
            use12Hours={false}
            onFocus={handleFocus}
            placeholder={[t('util.start'), t('util.end')]}
          />
        </Form.Item>
      ))}
    </div>
  );
};
